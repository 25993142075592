<template>
  <div :id="`menu-${buttonSection}`" :class="{ 'd-initial': true, 'menu-display': menuDisplayCSS }">
    <div v-for="(tray, index) in filteredButtonData" :id="tray.id" :key="index" class="buttontray-container">
      <div class="buttontray m-1" v-if="tray.buttons.length > 0">
        <label class="traytitle mb-2">
          {{ tray.trayName }}
        </label>
        <DetailButton
          :buttonText="trayButton.buttonName"
          :buttonIcon="trayButton.iconName"
          :buttonColor="trayButton.color || '#183449'"
          v-for="trayButton in tray.buttons"
          :key="trayButton.id"
          @click.native="clickButton(trayButton)" />
      </div>
    </div>
    <b-modal
      v-if="allowPermissionsModal"
      id="revoke-modal"
      ref="revokeModal"
      title="Revoke/Update Permissions"
      size="lg"
      :buttons="[
        { title: 'Cancel', variant: 'btn-secondary' },
        { title: 'Submit Request', variant: 'btn-accent', action: () => submitRequest() },
      ]">
      <template #body>
        <div class="mb-3">
          <label class="form-label">Update Request</label>
          <select class="form-select" v-model="updateRequest.action">
            <option value="replace">Revoke and Request Replacement for this URL</option>
            <option value="revoke">Revoke this URL</option>
            <option value="revokeAll">Revoke All URLs</option>
            <option value="modify">Modify URL Permissions</option>
            <option value="add">Add New URL</option>
          </select>
        </div>
        <div v-if="updateRequest.action === 'modify' || updateRequest.action === 'add'">
          <div v-if="updateRequest.action === 'add'" class="mb-3">
            <label class="form-label">Title</label>
            <input type="text" class="form-control" v-model.trim="updateRequest.remoteInfo.title" />
          </div>
          <div class="form-check ms-2">
            <input class="form-check-input" type="checkbox" v-model="updateRequest.remoteInfo.editInfo" id="editInfo" />
            <label class="form-check-label" for="editInfo">Edit Information</label>
          </div>
          <div class="form-check ms-2">
            <input class="form-check-input" type="checkbox" v-model="updateRequest.remoteInfo.addButtons" id="addButtons" />
            <label class="form-check-label" for="addButtons">Add Buttons</label>
          </div>
          <div class="form-check ms-2">
            <input class="form-check-input" type="checkbox" v-model="updateRequest.remoteInfo.sendNotifications" id="sendNotifications" />
            <label class="form-check-label" for="sendNotifications">Send Notifications</label>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import ButtonData from '../json/buttonData.json';
import DetailButton from './DetailButton';
import { newObjectMap } from '../json/listBuilderData.json';
import BModal from './common/BModal.vue';

export default {
  name: 'buttonMenu',
  components: {
    DetailButton,
    BModal,
  },
  props: ['buttonSection', 'context', 'dbContext', 'displayType', 'rightColumnKey', 'menuDisplayCSS'],
  data() {
    return {
      buttonData: ButtonData[this.buttonSection],
      inContext: false,
      sorting: false,
      viewArchive: false,
      filteredButtonData: ButtonData[this.buttonSection],
      menuSection: window.localStorage.getItem('MENU_SECTION') || 'main-menu',
      updateRequest: {
        action: 'replace',
        remoteInfo: {
          editInfo: false,
          addButtons: false,
          title: '',
          sendNotifications: false,
          origContext: this.getCampgroundKey,
        },
      },
      allowPermissionsModal: false,
    };
  },
  computed: {
    getRoute: function () {
      return this.context || this.$route.name;
    },
  },
  watch: {
    context: function () {
      this.filterButtonData();
    },
    dbContext: function () {
      this.filterButtonData();
    },
    '$store.state.remoteInfo.sendNotifications': function () {
      this.filterButtonData();
    },
  },
  created() {
    if (this.rightColumnKey == 'sort-button') {
      this.sorting = true;
    }
    this.filterButtonData();
    this.initialize();
  },
  methods: {
    submitRequest: async function () {
      this.updateRequest.remoteInfo = { ...this.$store.getters.remoteInfo, ...this.updateRequest.remoteInfo };
      this.updateRequest.remoteInfo.origContext = this.getCampgroundKey;
      const remoteBusinessUpdate = await firebase.functions().httpsCallable('remoteBusinessUpdate');
      let props = {
        detailId: this.$store.getters.detailId,
        action: 'requestUpdate',
        updateRequest: this.updateRequest,
        fbProject: this.$fbProject,
      };
      remoteBusinessUpdate(props);

      this.$message.create({
        title: 'Save Complete',
        body: 'Your request has been sent to the administrators.',
        buttons: [this.$message.button('OK')],
      });
    },
    toggleTray: function (index) {
      this.buttonData[index].displayButtons = !this.buttonData[index].displayButtons;
      this.filterButtonData();
    },
    filterButtonData: function () {
      if (!this.buttonData) {
        this.buttonData = ButtonData[this.buttonSection];
      }

      if (this.buttonData) {
        this.filteredButtonData = this.buttonData.map((tray) => {
          let newTray = Object.assign({}, tray);
          newTray.buttons = newTray.buttons.filter((trayButton) => {
            return this.displayCurrentButton(newTray, trayButton);
          });
          return newTray;
        });

        this.allowPermissionsModal = this.filteredButtonData.some((item) => item.id === 'remote-management');
      }
    },
    displayCurrentButton: function (tray, trayButton) {
      if (tray.remoteManagement) {
        if (!this.$route.meta.isRemote && tray.id === 'remote-management') return false;
        if (trayButton.id === 'send-detail-notification') {
          return this.$store.getters.remoteInfo.sendNotifications;
        }
        if (trayButton.id === 'switch-detail-page') {
          const detailPages = window.localStorage.getItem('REMOTE_DETAIL_PAGE_ACCESS');
          if (!detailPages) return false;
          return Object.keys(JSON.parse(detailPages)).length > 1;
        }
      } else if (this.$route.meta.isRemote) {
        return false;
      }
      const isResortAdmin =
        window.localStorage.getItem(`RESORT_SYSTEM_ADMIN`) == true || window.localStorage.getItem('RESORT_SYSTEM_ADMIN') == 'true' ? true : false;
      const adminLocations = JSON.parse(window.localStorage.getItem(`ADMIN_LOCATIONS`)) || [];
      const isAdminForLocation = adminLocations.includes(this.getCampgroundKey);
      const applist = JSON.parse(window.localStorage.getItem(`APP_LIST`)) || [];
      const isAdminForApp = (isAdminForLocation && applist.length == 1) || adminLocations.filter((value) => applist.includes(value)).length > 0;

      const canSendNotification = (JSON.parse(window.localStorage.getItem(`CAN_NOTIFY_LOCATIONS`)) || []).includes(this.getCampgroundKey);
      const editLocations = JSON.parse(window.localStorage.getItem(`EDIT_LOCATIONS`)) || [];
      const allLocations = JSON.parse(window.localStorage.getItem(`ALL_LOCATIONS`)) || [];
      if (trayButton.dev && process.env.NODE_ENV !== 'development') {
        return false;
      }
      if (trayButton.flag && !this[trayButton.flag]) {
        return false;
      }
      if (!tray.displayButtons) {
        return false;
      }
      if (tray.auth || trayButton.auth) {
        const authInfo = (trayButton.auth ? trayButton.auth : tray.auth) || [];
        if (
          (!authInfo.includes('isResortAdmin') || !isResortAdmin) &&
          (!authInfo.includes('isAdminForLocation') || !isAdminForLocation) &&
          (!authInfo.includes('canSendNotification') || !canSendNotification) &&
          (!authInfo.includes('editLocations') || !editLocations) &&
          (!authInfo.includes('isAdminForApp') || !isAdminForApp)
        ) {
          return false;
        }
      }

      if (trayButton.id == `switch-community`) {
        const locationAdminIntersection = editLocations.filter((value) => allLocations.includes(value));
        if (locationAdminIntersection.length < 2 && !isResortAdmin) return false;
      }

      if (trayButton.section) {
        if (trayButton.section == this.menuSection) {
          return true;
        }
        return false;
      }
      if (trayButton.id !== `sort-cancel` && trayButton.id !== 'sort-submit' && this.sorting) {
        return false;
      }
      if ((trayButton.id === `sort-cancel` || trayButton.id === `sort-submit`) && !this.sorting) {
        return false;
      }
      if ((trayButton.id == `view-archived` || trayButton.id == `scheduled-notifications`) && this.inContext) {
        return false;
      }
      if (trayButton.id == `view-active` && !this.inContext) {
        return false;
      }
      if (!trayButton.contexts) {
        return true;
      }
      if (this.$route.name == null) {
        return false;
      }
      return trayButton.contexts.includes(this.getRoute.split(`/`)[0]);
    },
    clickButton: async function (trayButton) {
      this.$emit('hideMenu');
      let type = this.displayType;
      let dbContext = this.dbContext;
      const trayId = trayButton['id'];
      if (trayButton.id == 'sort-alpha') {
        this.$message.create({
          title: 'Sort Alphabetically',
          body: 'Are you positive you want to sort this listing in alphabetical order?',
          buttons: [
            this.$message.button('Cancel', 'btn-secondary'),
            this.$message.button('Sort', 'btn-accent', () => {
              this.filterButtonData();
              this.$emit('action', 'sort-alpha');
            }),
          ],
        });
        return;
      } else if (trayButton.id.startsWith('sort-')) {
        this.sorting = !this.sorting;
      }
      if (trayButton.hasContext) {
        this.inContext = !this.inContext;
      }
      if (this.$fbProject == 'studio' && trayButton.studioDisabled) {
        this.$message.create({
          title: 'Feature Unavailable',
          body: 'This feature is not available inside AppMyCommunity Studio. <br>To recieve a demo of this feature, <a href="https://www.appmycommunity.com/schedule-a-call/" target="_blank">schedule time with us.</a>',
          buttons: [this.$message.button('OK')],
        });
        return;
      }
      switch (trayId) {
        case 'send-detail-notification':
          this.$router
            .push({
              name: 'remoteListingNoMenu',
              params: { context: trayId, prevContext: this.$route && this.$route.params ? this.$route.params.context : null },
            })
            .catch((err) => {
              console.error(err);
            });
          return;
        case 'page-permissions':
          this.$refs.revokeModal.show();
          return;
        // case 'staff-menu':
        case 'main-menu':
          window.localStorage.setItem('MENU_SECTION', trayId);
          window.location.href = '/';
          //this.$router.push({name: 'home'});
          return;
        case 'switch-detail-page': {
          const h = this.$createElement;
          // Using HTML string
          const titleVNode = h('div', { domProps: { innerHTML: 'Switch Page' } });
          // More complex structure
          const remoteDetailPageAccess = window.localStorage.getItem('REMOTE_DETAIL_PAGE_ACCESS');
          const detailPages = JSON.parse(remoteDetailPageAccess);
          const nodes = Object.keys(detailPages)
            .filter((x) => x !== this.$route.params.detailId)
            .map((x) => {
              return h(
                'b-link',
                {
                  class: ['text-start', 'underline', 'link-primary', 'd-block'],
                  on: {
                    click: () => {
                      this.$root.$emit('bv::hide::modal', 'switch-modal', '#btnShow');
                      window.localStorage.setItem('REMOTE_DETAIL_LAST_EDITED', x);
                      this.$router.replace({
                        name: 'remoteUpdateDetail',
                        params: { detailId: x, remoteInfo: detailPages[x] },
                      });
                    },
                  },
                },
                detailPages[x].detailTitle
              );
            });
          const messageVNode = h('div', { class: ['foobar'] }, nodes);
          this.$message.create({
            title: 'Switch Page',
            body: messageVNode,
            buttons: [this.$message.button('Cancel')],
          });
          break;
        }

        case 'custom-forms':
        case 'hunts':
        case 'store': {
          let prevContext = this.$route && this.$route.params ? this.$route.params.context : null;
          if (trayId == 'user-group' && !(await this.checkForMessages())) prevContext = 'systemwide-notification';
          this.$store.commit('updateParams', { context: trayId, prevContext: prevContext, path: 'root^' });
          this.$router
            .push({
              name: 'listingNoMenuFolders',
              params: { context: trayId, prevContext, path: 'root^' },
            })
            .catch((err) => {
              console.error(err);
            });
          return;
        }

        case 'category':
        case 'user-group':
        case 'locations':
        case 'rewards-users':
        case 'ads.all':
        case 'users':
        case 'usermgmt':
        case 'app-design':
        case 'bookings':
        case 'switch-community':
        case 'orders':
        case 'file-mgmt':
        case 'systemwide-notification':
        case 'import':
        case 'export':
        case 'splashscreen':
        case 'dollars':
        case 'email':
        case 'dollarsaccountstatus': {
          let prevContext = this.$route && this.$route.params ? this.$route.params.context : null;
          if (trayId == 'user-group' && !(await this.checkForMessages())) prevContext = 'systemwide-notification';
          this.$store.commit('updateParams', { context: trayId, prevContext: prevContext });
          this.$router
            .push({
              name: 'listingNoMenu',
              params: { context: trayId, prevContext },
            })
            .catch((err) => {
              console.error(err);
            });
          return;
        }
        case 'marketing':
          this.$store.commit('updateParams', { context: trayId });
          this.$router.push({ name: trayId }).catch((err) => {
            console.error(err);
          });
          return;
        case 'marketing-asset-editor':
          this.$router.push({ name: 'marketingAssetEditor' }).catch((err) => {
            console.error(err);
          });
          return;
        case 'activities-printout': {
          const routeData = this.$router.resolve({ name: 'activitiesPrintout' });
          window.open(routeData.href, '_blank');
          return;
        }
        case 'analytics': {
          const routeData = this.$router.resolve({ name: 'analytics' });
          window.open(routeData.href, '_blank');
          return;
        }
        case 'community-setup':
        case 'add-community':
          this.$router.push({ name: 'home/action', params: { action: trayId } }).catch((err) => {
            console.error(err);
          });
          return;
        case 'create-event':
          type = 'events';
          dbContext = 'events-resort';
          this.$route.params.action = 'add';
        //fallthrough
        case 'add-event':
        case 'add-button':
        case 'update-map': {
          let newParams = { ...this.$route.params, ...{ trayId: trayId } };
          if (!type || newObjectMap[type] || (dbContext && dbContext.includes('-categories'))) {
            if (this.$route.name == 'home' && ['add-button'].includes(trayId)) {
              this.$store.commit('updateParams', newParams);
              this.$router.push({ name: 'home/action', params: { action: 'add' } }).catch((err) => {
                console.error(err);
              });
              return;
            }
            this.$emit('action', trayId);
          } else {
            const storeParams = {
              menuId: newParams.menuId,
              gridMenuKey: newParams.gridMenuKey,
              tab: newParams.tab,
              trayId: newParams.trayId,
              itemId: this.itemId,
              path: newParams.path,
            };
            this.$store.commit('updateParams', storeParams);
            console.log('newParams', newParams);
            this.$router.push({ name: 'detailAdd', params: { dbContext: dbContext, type: type || 'business' } }).catch((err) => {
              console.error(err);
            });
          }
          return;
        }
        case 'send-notification': {
          this.pushToNotification();
        }
      }
      this.filterButtonData();
      this.$emit('action', trayId);
    },
    initialize() {
      if (this.context == 'export') this.$emit('action', 'add-button');
    },
  },
};
</script>

<style>
.buttontray {
  /* width: 30%; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(88px, 1fr));
  grid-gap: 4px;
  background-color: white;
}

div#activity-actions {
  position: sticky;
  top: 0px;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
  padding-bottom: 0.25rem;
}
</style>
