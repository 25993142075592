<template>
  <div :class="itemHasPassedClass()">
    <div class="simple-title list-item" data-bs-toggle="collapse" :data-bs-target="'#' + getCollapseId">
      {{ getPoints }}{{ item.title }} &nbsp;
      <i class="material-icons expand"></i>
      <i class="material-icons show" v-if="dbContext !== 'import'" @click.stop="editItem">edit</i>
      <i class="material-icons show" v-if="isMessage" @click.stop="duplicateItem">content_copy</i>
      <i class="material-icons show" @click.stop="openDeleteModal">delete</i>
    </div>
    <div :id="getCollapseId" class="collapse mt-2" :class="{ show: isEditMode }">
      <div class="row">
        <div class="col"><span v-html="item.htmlDescription || item.description"></span></div>
      </div>
      <div v-if="isMessage" class="row">
        <div class="col">
          <div>
            <div v-if="isMessage" class="fw-bold mt-2">Delivery Date</div>
            <div>{{ getFormattedDay(item.deliveryTime) }} {{ getFormattedTime(item.deliveryTime) }}</div>
          </div>
        </div>
      </div>
      <img class="w-100" v-if="item.drawable" :src="item.drawable" />
      <button v-if="item.url" class="btn m-1 btn-secondary" type="button" @click.stop="openURL()">OPEN URL</button>
      <template v-if="item.detailButtons">
        <div class="row buttonRow">
          <div class="col" v-for="(button, index) in item.detailButtons" v-bind:key="index">
            <DetailButton
              :buttonText="button.title"
              :buttonIcon="button.icon"
              :svg="button.iconData ? button.iconData.svg : null"
              :action="button.action"
              :actionKey="button.actionKey" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import DetailButton from '../DetailButton.vue';
export default {
  components: { DetailButton },
  props: {
    item: Object,
    itemId: String,
    dbContext: String,
    selectedItem: String,
    view: String,
    isArchived: Boolean,
    isSystemWide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getCollapseId: function () {
      return `collapse-${this.itemId}`;
    },
    isEditMode: function () {
      return this.itemId == this.selectedItem;
    },
    isMessage: function () {
      return this.dbContext == 'messages' || this.dbContext == 'systemwide-notification';
    },
    getPoints: function () {
      if (this.item.points) {
        return `${this.item.points.toLocaleString()} - `;
      }
      return null;
    },
  },
  methods: {
    openURL: function () {
      window.open(this.item.url, '_blank');
    },
    editItem: function () {
      this.$emit('editItem', this.itemId);
    },
    async openDeleteModal() {
      this.$message.create({
        title: 'Delete Item',
        body: 'Are you certain you want to delete?',
        buttons: [
          this.$message.button('Cancel', 'btn-secondary'),
          this.$message.button('Delete', 'btn-danger', async () => {
            switch (this.dbContext) {
              case 'systemwide-notification': {
                firebase
                  .database()
                  .ref('resort-navigator')
                  .child('location-data')
                  .child('system-data')
                  .child('messages')
                  .child(this.itemId)
                  .child('endTime')
                  .set(Date.now());
                break;
              }
              case 'splashscreen': {
                const splashData = (await firebase.database().ref('resort-navigator').child('app-data').once('value')).val();
                const appKeys = Object.keys(splashData);

                console.log('🚀 ~ file: ListBuilder.vue ~ line 277 ~ getDataList:function ~ appKeys', appKeys);
                for (let i = 0; i < appKeys.length; i++) {
                  await firebase
                    .database()
                    .ref('resort-navigator')
                    .child('app-data')
                    .child(appKeys[i])
                    .child('splash-screen-data')
                    .child('splashScreens')
                    .child(this.itemId)
                    .remove();
                }
                break;
              }
              case 'messages':
              case 'giveaways':
                if (this.isArchived) {
                  this.getContextRef(null, null, this.isArchived).child(this.itemId).remove();
                } else {
                  this.getContextRef().child(this.itemId).child('endTime').set(Date.now());
                }
                break;
              case 'messages-scheduled': {
                const campgroundKey =
                  this.isSystemWide || !this.$route.params || this.$route.params.context !== 'systemwide-notification'
                    ? this.getCampgroundKey
                    : 'system-data';
                this.getContextRef(this.dbContext, campgroundKey).child(this.itemId).remove();
                break;
              }
              case 'send-detail-notification':
              case 'remote-messages-scheduled': {
                const action = this.dbContext === 'remote-messages-scheduled' ? 'deleteRecurring' : 'deleteMessage';
                await firebase.functions().httpsCallable('remoteBusinessUpdate')({
                  uuid: this.$route.params.uuid,
                  action: action,
                  newItem: { endTime: Date.now() },
                  editKey: this.itemId,
                });
                break;
              }
              case 'category':
              case 'user-group': {
                const campgroundKey =
                  this.isSystemWide || (this.$route && this.$route.params.prevContext == 'systemwide-notification')
                    ? 'system-data'
                    : this.getCampgroundKey;
                this.getLocationRef(campgroundKey).child('messages-config').child('topics').child(this.itemId).remove();
                break;
              }
              case 'ads/all': {
                if (this.item.pages) {
                  const itemsToRemove = this.item.pages.reduce((acc, page) => {
                    acc[`${page}/${this.itemId}`] = null;
                    return acc;
                  }, {});
                  this.getContextRef('ads/pages').update(itemsToRemove);
                }
              }
              // eslint-disable-next-line no-fallthrough
              default:
                this.getContextRef().child(this.itemId).remove();
            }
            this.$emit('deleteItem', this.itemId);
            this.$emit('action', 'reload');
          }),
        ],
      });
    },
    duplicateItem: function () {
      this.$emit('action', 'duplicate', this.itemId);
      //this.$emit('action', 'reload');
    },
    itemHasPassedClass: function () {
      return this.isMessage && this.item.endTime < Date.now() && this.item.endTime !== 0 && this.view !== 'view-archived' ? 'message-passed' : '';
    },
  },
};
</script>

<style>
.show {
  display: inline !important;
}
.message-passed {
  background-color: red;
}
</style>
